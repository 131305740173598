const layoutAux = {
  position: "absolute",
  zIndex: "1",
  width: "100%",
  height: "100%",
  display: "block",
  left: "0",
  top: "0",
  content: "''",
};

const parallaxStyle = {
  parallax: {
    height: "100vh",
    maxHeight: "1600px",
    overflow: "hidden",
    position: "relative",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
  },
  filter: {},
  primaryColor: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)",
    },
    "&:after": {
      background:
        "linear-gradient(60deg,rgba(225,190,231,.56),rgba(186,104,200,.95))",
    },
    "&:after,&:before": {
      ...layoutAux,
    },
  },
  roseColor: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)",
    },
    "&:after": {
      background:
        "linear-gradient(60deg,rgba(248,187,208,.56),rgba(240,98,146,.95))",
    },
    "&:after,&:before": {
      ...layoutAux,
    },
  },
  darkColor: {
    // Apricot: keep &:before only
    "&:before": {
      background: "rgba(0, 0, 0, 0.45)",
      ...layoutAux,
    },
  },
  // Apricot: add for courses list
  coursesColor: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.25)",
    },
    "&:after": {
      background: "linear-gradient(60deg,rgba(11,3,32,.6),rgba(77,77,125,.95))",
    },
    "&:after,&:before": {
      ...layoutAux,
    },
  },
  // Apricot: add for lecture list (course page)
  courseColor: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.25)",
    },
    "&:after": {
      background:
        "linear-gradient(60deg,rgba(255,128,64,.15),rgba(127,77,77,.15))",
    },
    "&:after,&:before": {
      ...layoutAux,
    },
  },
  successColor: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.4)",
    },
    "&:after": {
      background:
        "linear-gradient(60deg,rgba(165,214,167,.46),rgba(102,187,106,.95))",
    },
    "&:after,&:before": {
      ...layoutAux,
    },
  },
  warningColor: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)",
    },
    "&:after": {
      background:
        "linear-gradient(60deg,rgba(255,224,178,.56),rgba(255,183,77,.95))",
    },
    "&:after,&:before": {
      ...layoutAux,
    },
  },
  dangerColor: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)",
    },
    "&:after": {
      background:
        "linear-gradient(60deg,hsla(0,73%,77%,.15),rgba(239,83,80,.35))",
    },
    "&:after,&:before": {
      ...layoutAux,
    },
  },
  clearColor: {
    // "&:before": {
    //   background: "rgba(0, 0, 0, 0.5)"
    // },
    "&:after": {
      background:
        "linear-gradient(60deg,hsla(0,73%,77%,.15),rgba(60,60,60,.35))",
    },
    "&:after,&:before": {
      ...layoutAux,
    },
  },
  // Apricot: have parallax work as a banner, with shorter height
  banner: {
    "@media (orientation: landscape)": {
      height: "40vh",
      maxHeight: "500px",
    },
    height: "30vh",
    minHeight: "30vh",
    maxHeight: "400px",
  },
  short: {
    height: "30vh",
    minHeight: "30vh",
    maxHeight: "400px",
  },
};

export default parallaxStyle;
