/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  title,
} from "assets/jss/material-kit-pro-react.jsx";

const infoStyle = {
  infoArea: {
    maxWidth: "360px",
    margin: "0 auto",
    padding: "70px 0 30px",
  },
  iconWrapper: {
    float: "left",
    marginTop: "21px",
    marginRight: "10px",
  },
  primary: {
    color: primaryColor[0],
  },
  warning: {
    color: warningColor[0],
  },
  clipbrd: {
    color: "#C61D39", // Apricot
  },
  success: {
    color: successColor[0],
  },
  info: {
    color: "#9870AB", // Apricot
  },
  note: {
    color: "#57589E", // Apricot
  },
  test: {
    color: "#2D73C4", // Apricot
  },
  white: { color: "white" },
  icon: {
    width: "2.25rem",
    height: "2.25rem",
    fontSize: "2.25rem",
  },
  descriptionWrapper: {
    color: "grayColor[0]",
    overflow: "hidden",
  },
  title: {
    ...title,
    margin: "1.25rem 0 0.875rem !important",
    fontSize: "1.5rem",
    fontWeight: 500,
    minHeight: "unset",
  },
  description: {
    color: "#343434", // Apricot
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "1rem",
    // "& p": {
    //   color: "#343434", // Apricot
    //   fontSize: "1rem",
    // },
  },
  iconWrapperVertical: {
    float: "none",
  },
  iconVertical: {
    width: "61px",
    height: "61px",
  },
};

export default infoStyle;
