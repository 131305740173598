/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  blackColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.jsx";

const cardHeaderStyle = {
  cardHeader: {
    borderRadius: "3px",
    padding: "1rem 15px",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "-30px",
    border: "0",
    marginBottom: "0",
  },
  cardHeaderPlain: {
    marginLeft: "0px",
    marginRight: "0px",
    "&$cardHeaderImage": {
      margin: "0 !important",
    },
  },
  cardHeaderImage: {
    position: "relative",
    padding: "0",
    zIndex: "1",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "-30px",
    borderRadius: "6px",
    "& img": {
      width: "100%",
      borderRadius: "6px",
      pointerEvents: "none",
      boxShadow:
        "0 5px 15px -8px rgba(" +
        hexToRgb(blackColor) +
        ", 0.24), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)",
    },
    "& a": {
      display: "block",
    },
  },
  noShadow: {
    "& img": {
      boxShadow: "none !important",
    },
  },
  cardHeaderContact: {
    margin: "0 15px",
    marginTop: "-20px",
  },
  cardHeaderSignup: {
    // Apricot
    textAlign: "center",
    color: "white",
    position: "relative",
    width: "100%",
    margin: "0 0 15px 0",
    padding: "20px 0",
    overflow: "hidden",
    // glass:
    background: "#050830",
    borderRadius: "0.45em 0.45em 0 0",
    border: "1px solid #191919",
    boxShadow: "inset 0 0 2px 1px rgba(255, 255, 255, 0.08)",
    // Gradient line at top edge
    "&:after": {
      content: "''",
      height: "1px",
      width: "120px",
      position: "absolute",
      left: "60px",
      top: 0,
      background:
        "linear-gradient(left, transparent, #444, #b6b6b8, #444, transparent)",
    },
    // Small flash
    "&:before": {
      content: "''",
      width: "8px",
      height: "5px",
      position: "absolute",
      left: "116px",
      top: "-7px",
      borderRadius: "50%",
      boxShadow: "0 0 6px 4px #fff",
    },
    // large reflection on the right part
    "& p:before": {
      content: "''",
      width: "320px",
      height: "130px",
      position: "absolute",
      top: 0,
      left: "45px",

      transform: "rotate(75deg)",
      background:
        "linear-gradient(50deg, rgba(255,255,255,0.15), rgba(0,0,0,0))",
      pointerEvents: "none",
    },
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
};

export default cardHeaderStyle;
