import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import parallaxStyle from "assets/jss/material-kit-pro-react/components/parallaxStyle.jsx";

const isNode = require("detect-node");

class Parallax extends React.Component {
  constructor(props) {
    // console.log('Parallax1 - window: ', window === defined ? 'defined' : 'undefined');
    super(props);

    if (!isNode) {
      let windowScrollTop;
      if (window.innerWidth >= 768) {
        windowScrollTop = window.pageYOffset / 3;
      } else {
        windowScrollTop = 0;
      }
      this.state = {
        transform: "translate3d(0," + windowScrollTop + "px,0)",
      };
      this.resetTransform = this.resetTransform.bind(this);
    }
  }
  componentDidMount() {
    if (!isNode && window.innerWidth >= 768) {
      var windowScrollTop = window.pageYOffset / 3;
      this.setState({
        transform: "translate3d(0," + windowScrollTop + "px,0)",
      });
      window.addEventListener("scroll", this.resetTransform);
    }
  }
  componentWillUnmount() {
    if (!isNode && window.innerWidth >= 768) {
      window.removeEventListener("scroll", this.resetTransform);
    }
  }
  resetTransform() {
    var windowScrollTop = window.pageYOffset / 3;
    this.setState({
      transform: "translate3d(0," + windowScrollTop + "px,0)",
    });
  }

  render() {
    const {
      classes,
      filter,
      className,
      children,
      style,
      image,
      banner,
      short,
    } = this.props;
    const parallaxClasses = classNames({
      [classes.parallax]: true,
      [classes[filter + "Color"]]: filter !== undefined,
      [classes.banner]: banner,
      [classes.short]: short,
      [className]: className !== undefined,
    });
    return (
      <div
        className={parallaxClasses}
        title={"Apricad, Courses of Nursing"}
        style={{
          ...style,
          backgroundImage: "url(" + image + ")",
          ...this.state,
        }}
        ref="parallax">
        {children}
      </div>
    );
  }
}

Parallax.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  filter: PropTypes.oneOf([
    "primary",
    "rose",
    "dark",
    "course",
    "courses",
    "success",
    "warning",
    "danger",
    "clear",
  ]),
  children: PropTypes.node,
  style: PropTypes.string,
  image: PropTypes.string,
  banner: PropTypes.bool,
  short: PropTypes.bool,
};

export default withStyles(parallaxStyle)(Parallax);
